import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";

function Casting() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>

        <p style={{ color: "white" }}>
        <h3 style={{ fontSize: "2.5em" }}>
            <span className="purple"> Casting </span>
        </h3>

        <h3 style={{ fontSize: "1.5em" }}>
            <span className="purple">  Casting Call Medellin  </span>  
        </h3>
        <p>
            Acosta Productions está realizando un casting en Medellín, Colombia. Buscamos mujeres entre 18 - 55 años para contenido de video para adultos.
        </p>
        <p>
            Si es seleccionada, para la primera sesión, el pago es de 444.000 pesos. Si la modela se desempeña bien durante la sesión, puede ser invitada a volver a realizar más sesiones con un pago mayor. Durante la sesión, la modela será entrevistada y tendrá sexo oral y vaginal con un modelo gringo frente a la cámara. Se utilizarán condones para las relaciones vaginales.
        </p>
        <p>
            La sesión durará entre 35 - 45 minutos. Una vez seleccionados, los modelas recibirán las reglas.
        </p>
        <p>
            Las modelas deben firmar una autorización legal completa para el video. El video puede aparecer en Internet. Todas las modelos deberán usar un nombre artístico para la sesión.
        </p>

        <p>
            Debe tener al menos 18 años de edad para postularse. Para postularse, envíe un correo electrónico a AndresAcostaXXX1@gmail.com con la siguiente información:
        </p>
        
        <p>
            1. Una foto de cuerpo entero con ropa.
        </p>
        <p>
            2. Copia de identificación emitida por el gobierno.
        </p>
        <p>
            3. Indique su edad, ubicación y disponibilidad.
        </p>
        <h3 style={{ fontSize: "1.5em" }}>
            <span className="purple">  AndresAcostaXXX1@gmail.com  </span>  
        </h3>


        </p>
        
      </Container>
    </Container>
  );
}

export default Casting;
