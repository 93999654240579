import React from "react";
import Typewriter from "typewriter-effect";
import './type.css';

function Type() {
  return (
    <div className="typewriter-text">
    <Typewriter
      options={{
        strings: [
          "Actor",
          "Entertainer",
          "Creator",
          "Andres",
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 50,
      }}
      style={{ color: 'red' }}
    />
    </div>

  );
}

export default Type;
